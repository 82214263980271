import {Input} from "postcss";

window.$ = window.jQuery = require('jquery/dist/jquery.min');
require('bootstrap/dist/js/bootstrap.min');
import "inputmask";
import {getElement} from "bootstrap/js/src/util";
import {toUpper} from "lodash/string"; //npm i inputmask

//OWL CAROUSEL (npm i owl.carousel)
require('owl.carousel/dist/owl.carousel');

//------------------------------------------------------// Header

if (document.getElementById('logos')) {
    $('.owl-carousel').owlCarousel({
        center:true,
        items: 4,
        loop: true,
        margin: 10,
        autoplay: true,
        autoplayTimeout: 2000,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2
            },
            768:{
                items: 3
            },
            992: {
                items: 4
            },
            1200: {
                items: 5
            }
        }
    })
}

//------------------------------------------------------// Jq mask

let elementos = document.getElementsByTagName('input');
if(elementos)Inputmask().mask(elementos);

//------------------------------------------------------// form

$(document).ready(function() { //recarrega a pag com os dados ja preenchidos
    $('option').show(function() {
        let formOption = $('#option').val();
        if(formOption >= 1){
            document.getElementById('petForm').classList.remove('d-none');
        }else{
            document.getElementById('petForm').classList.add('d-none');
        }
    });

});


$('#option').on('click', function (){ //ativar o select com o click

   let formOption = $('#option').val();
   if(formOption >= 1){
       document.getElementById('petForm').classList.remove('d-none');
   }else{
       document.getElementById('petForm').classList.add('d-none');
   }
});

$('#send').on('click', function (){
    document.getElementById('send').classList.add('d-none')
    document.getElementById('charging').classList.remove('d-none')

})
